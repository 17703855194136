import { createSelector } from 'reselect';

import { PostService } from './types';

import { State } from '../../store';
import { ProvidersState } from './state';

import { Country } from '../../config/countries';
import { getProviderSlug } from '../../config/country-slug';

import { getCountryProvider, getProviderParcelTemplates, isInPostLocker, isInPostReturn } from '../../config/providers';

import { getFormKeys } from '../../components/rates/form/formRules';
import { CURRENCY } from '../../helpers/currency';
import { ProvidersMap } from '../../client';

export const getProvidersState = (state: State): ProvidersState => state.providers;

export const getInboundProviders = createSelector(getProvidersState, ({ inboundList }) => inboundList);

export const getMode = createSelector(getProvidersState, ({ mode }) => mode);
export const isWithoutDocs = createSelector(getProvidersState, ({ noDocs }) => noDocs);
export const isCalculator = createSelector(getMode, (mode) => mode === 'calculator');
export const isDelivers = createSelector(getMode, (mode) => mode === 'delivers');

export const getDispatchCountry = createSelector(getProvidersState, ({ dispatchCountry }): Country => dispatchCountry);
export const getInboundCountry = createSelector(getProvidersState, ({ inboundCountry }): Country => inboundCountry);

export const getProvidersPair = createSelector(
  getProvidersState,
  ({ providersPair }): ProvidersMap | null => providersPair
);

export const hasProvidersPair = createSelector(getProvidersPair, (providersPair): boolean => Boolean(providersPair));

export const getCurrentProvider = createSelector(getProvidersState, ({ dispatchCountry }) => dispatchCountry.provider);
export const getInboundProvider = createSelector(getProvidersState, ({ inboundCountry }) => inboundCountry.provider);

export const getDispatchProvider = createSelector(getProvidersState, ({ dispatchCountry }) =>
  getCountryProvider(dispatchCountry)
);

export const getBookService = createSelector(getProvidersState, ({ bookService }) => bookService);
export const getFAQList = createSelector(getDispatchProvider, getBookService, ({ faq }, service) =>
  Array.isArray(faq[service]) ? faq[service] : []
);

/**
 * Detect if we should send hardcoded drop off point
 *  ̶(̶c̶u̶r̶r̶e̶n̶t̶l̶y̶ ̶f̶o̶r̶ ̶l̶i̶v̶e̶ ̶e̶n̶v̶ ̶o̶n̶l̶y̶)̶
 *  live & dev
 */
export const shouldHardCodeDropOff = createSelector(getDispatchCountry, getBookService, ({ provider }, service) =>
  isInPostReturn(provider, service)
);

export const showDropOffMap = createSelector(
  getDispatchCountry,
  getBookService,
  // show drop off map only on prod
  // because for dev env we will use hardcoded dropOff point
  ({ provider }, service) => isInPostLocker(provider, service)
);

// filters
export const getParcelPrice = createSelector(getProvidersState, ({ price: v }): string => v);
export const getParcelDeliveryDiff = createSelector(getProvidersState, ({ deliveryDiff: v }): string => v);
export const getCurrentCurrency = createSelector(getDispatchCountry, ({ currency: v }): CURRENCY => v);
export const getParcelDiscountPrice = createSelector(getProvidersState, ({ discountPrice: v }): string => v);
export const getParcelDiscount = createSelector(getProvidersState, ({ discount: v }) => v);
export const getParcelPriceWithDisc = createSelector(getProvidersState, ({ price, discountPrice, discount }): string =>
  discount ? discountPrice : price
);
export const getParcelWeight = createSelector(getProvidersState, ({ weight: v }): number => v);
export const getParcelWidth = createSelector(getProvidersState, ({ width: v }): number => v);
export const getParcelHeight = createSelector(getProvidersState, ({ height: v }): number => v);
export const getParcelLength = createSelector(getProvidersState, ({ length: v }): number => v);
export const getSize = createSelector(
  getParcelWidth,
  getParcelHeight,
  getParcelLength,
  (width, height, length) => `${width}x${height}x${length}`
);

// providers params max values
export const getMaxParcelWeight = createSelector(
  getDispatchProvider,
  getBookService,
  ({ maxWeight, services }, bookService) => {
    const settings = services[bookService];

    return settings && settings.maxWeight ? settings.maxWeight : maxWeight;
  }
);
export const getMaxParcelWidth = createSelector(getDispatchProvider, ({ maxWidth }) => maxWidth);
export const getMaxParcelHeight = createSelector(getDispatchProvider, ({ maxHeight }) => maxHeight);
export const getMaxParcelLength = createSelector(getDispatchProvider, ({ maxLength }) => maxLength);

export const geParcelTemplates = createSelector(getDispatchCountry, getBookService, ({ provider }, service) =>
  getProviderParcelTemplates(provider, service)
);
export const geParcelTemplate = createSelector(getProvidersState, geParcelTemplates, ({ parcelTemplate }, templates) =>
  templates.find((t) => t.type === parcelTemplate)
);

// used for home page calculator
export const getCalculatorParams = createSelector(
  getDispatchCountry,
  getInboundCountry,
  getParcelWeight,
  (from, to, weight) => ({
    slug: getProviderSlug(from.provider),
    to: to.provider,
    weight,
  })
);

// services
export const getDelivers = createSelector(getProvidersState, ({ delivers }) => delivers);
export const getActiveDeliver = createSelector(getDelivers, (delivers) => delivers[0]);

/**
 * Indicate if "Maintenance" mode is enabled
 */
export const isMaintenance = createSelector(getActiveDeliver, (deliver) =>
  Boolean(deliver && deliver.maintenance_mode)
);

/**
 * Select maintenance message for active deliver
 */
export const getMaintenance = createSelector(isMaintenance, getActiveDeliver, (enabled, deliver) =>
  enabled && deliver ? deliver.maintenance_text : null
);
export const getNoDocsDiscountPrice = createSelector(
  getActiveDeliver,
  ({ no_docs_price = 0, no_docs_currency }): string =>
    no_docs_price ? `${no_docs_price} ${no_docs_currency}`.trim() : ''
);
export const getDeliversServices = createSelector(getActiveDeliver, getDispatchProvider, (deliver, provider) =>
  deliver
    ? Object.entries(deliver.services).map(
        ([type, service]): PostService => {
          const serviceConfig = provider.services && provider.services[type];

          return {
            ...service,
            type,
            analytics: (serviceConfig && serviceConfig.analytics) || {},
          };
        }
      )
    : []
);
export const getServiceAnalytics = createSelector(getDispatchProvider, getBookService, ({ services }, book) => {
  const curr = services && services[book] && services[book].analytics;

  return curr || {};
});

export const hasServiceCustomSize = createSelector(
  getDispatchProvider,
  getBookService,
  () =>
    // // by default `customSize` is `true`
    // const { customSize = true } = (services && services[book] && services[book]) || {};
    //
    // return customSize;

    // for now disable it for the all services
    false
);

// payment flow
export const isProcessPiraeus = createSelector(getProvidersState, ({ processPiraeus }) => processPiraeus);

// api errors
export const getFormErrors = createSelector(getProvidersState, ({ errors }) =>
  getFormKeys().reduce((acc, key) => {
    if (errors[key]) {
      const element = errors[key] as string[] | string;

      acc[key] = Array.isArray(element) ? (element as string[]).join('\n') : String(element);
    }

    return acc;
  }, {} as Record<string, string>)
);

export const getAlertErrors = createSelector(getProvidersState, ({ alertErrors }) =>
  Object.entries(alertErrors).map(([k, v]) => ({
    text: Array.isArray(v) ? v.join('\n') : v,
    key: k,
  }))
);

export const hasAlertErrors = (key: string) =>
  createSelector(getProvidersState, ({ alertErrors }): boolean => key in alertErrors);
