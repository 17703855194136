import NextI18Next from 'next-i18next';

const locales = ['ua', 'en', 'ru', 'el'];
const [defaultLanguage] = locales;

const localeSubpaths = locales.reduce((acc: Record<string, string>, lang: string) => {
  acc[lang] = lang;

  return acc;
}, {} as Record<string, string>);

// create i18next instance
const nextI18Next = new NextI18Next({
  localeSubpaths,
  defaultLanguage,
  fallbackLng: defaultLanguage,
  returnObjects: true,
  otherLanguages: locales,
});

// Optionally, export class methods as named exports
export const { appWithTranslation, withTranslation, useTranslation, i18n, Link, Router } = nextI18Next;

export default nextI18Next;
