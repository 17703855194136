/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect } from 'react';

import Tooltip from 'rc-tooltip';
import Slider, { Handle } from 'rc-slider';

export interface SliderProps extends ElementReactLibs.ComponentProps<{}> {
  min?: number | string;
  max?: number | string;
  step?: number | string;
  value?: number | number[];
  showInput?: boolean;
  showInputControls?: boolean;
  showTooltip?: boolean;
  showStops?: boolean;
  disabled?: boolean;
  range?: boolean;
  vertical?: boolean;
  height?: string;
  formatTooltip?(): void;
  onChange?(value: number): void;
}

const CustomHandle = React.forwardRef((props, ref) => {
  const { showTooltip, value, dragging, index, ...restProps } = props as any;

  useEffect(() => {
    if (!showTooltip) {
      return;
    }

    try {
      (ref as any).current.popupRef.current.alignRef.current.forceAlign();
    } catch (e) {
      // do something with error
    }
  }, [value, dragging, showTooltip]);

  if (!showTooltip) {
    return <Handle value={value} {...restProps} />;
  }

  return (
    <Tooltip
      ref={ref}
      overlay={value}
      visible={dragging}
      key={index}
      trigger="hover"
      placement="top"
      prefixCls="rc-slider-tooltip"
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
});

CustomHandle.displayName = 'CustomHandle';

const CustomSlider: React.FC<SliderProps> = ({
  min = 0,
  max = 100,
  step,
  value = 0,
  disabled,
  showTooltip,
  onChange,
}) => {
  const props = {
    min,
    max,
    step,
    value,
  };

  const handle = (_props: any) => <CustomHandle showTooltip={showTooltip} {..._props} />;

  const handleChange = useCallback(
    (val: number) => {
      if (onChange) {
        onChange(val);
      }
    },
    [onChange]
  );

  // @ts-ignore
  return <Slider {...props} disabled={disabled} onChange={handleChange} handle={handle} />;
};

export default CustomSlider;
