import { TFunction } from 'next-i18next';

import { validateName } from '../../../helpers/validators/name';

const getErrors = (key: string, errors: Record<string, string>) => [
  {
    // @ts-ignore
    validator: (rule, value, callback) => {
      if (errors && errors[key]) {
        callback(errors[key]);

        return;
      }

      callback();
    },
  },
];

export const getFormRules = (t: TFunction, errors: Record<string, string>) => {
  /**
   * Schema should contain all fields from the `Rates` form
   * In this way we can render API errors in the form fields
   */
  const schema = {
    // sender rules
    sender_dropOffPoint: [
      {
        required: true,
        message: t('rates:rates.validation.dropOffPoint'),
      },
    ],
    sender_sendMethod: [{ required: true, message: t('rates:rates.validation.sendMethod') }],
    sender_email: [
      {
        required: true,
        message: t('common:validation.required'),
      },
      {
        type: 'email',
        message: t('common:validation.email'),
        trigger: ['blur', 'change'],
      },
    ],
    sender_phone: [
      { required: true, message: t('common:validation.required') },
      {
        min: 11,
        max: 13,
        message: t('common:validation.phone'),
      },
    ],
    sender_fixedPhone: [],
    sender_firstName: [
      {
        required: true,
        message: t('common:validation.required'),
      },
      {
        validator: validateName,
      },
    ],
    sender_lastName: [
      { required: true, message: t('common:validation.required') },
      {
        validator: validateName,
      },
    ],
    sender_street: [{ required: true, message: t('common:validation.required') }],
    sender_buildingNumber: [
      {
        required: true,
        message: t('common:validation.required'),
      },
      {
        min: 1,
        max: 7,
        message: t('common:validation.maxLength', { max: 7 }),
      },
    ],
    sender_apartments: [],
    sender_houseName: [],
    sender_city: [
      {
        required: true,
        message: t('common:validation.required'),
      },
      // { min: 3, message: 'Minimum length 3 characters', trigger: 'blur' },
    ],
    sender_postCode: [{ required: true, message: t('common:validation.required') }],

    // receiver rules
    receiver_phone: [
      { required: true, message: t('common:validation.required') },
      {
        min: 11,
        max: 12,
        message: t('common:validation.phone'),
      },
    ],
    receiver_firstName: [
      {
        required: true,
        message: t('common:validation.required'),
      },
      {
        validator: validateName,
      },
    ],
    receiver_lastName: [
      { required: true, message: t('common:validation.required') },
      {
        validator: validateName,
      },
    ],
    receiver_street: [{ required: true, message: t('common:validation.required') }],
    receiver_apartments: [],
    receiver_region: [],
    receiver_buildingNumber: [
      {
        required: true,
        message: t('common:validation.required'),
      },
      {
        min: 1,
        max: 7,
        message: t('common:validation.maxLength', { max: 7 }),
      },
    ],
    receiver_city: [
      { required: true, message: t('common:validation.required') },
      // { min: 3, message: 'Minimum length 3 characters' },
    ],
    receiver_postCode: [{ required: true, message: t('common:validation.required') }],
    sender_dispatchDate: [
      {
        required: true,
        message: t('rates:rates.validation.dispatchDate'),
      },
    ],
  };

  // apply dynamic API errors
  Object.keys(schema).forEach((key) => {
    // @ts-ignore
    schema[key] = [...schema[key], ...getErrors(key, errors)];
  });

  return schema;
};

export const getFormKeys = (): string[] => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const t = () => {};

  return Object.keys(getFormRules(t, {}));
};
