import React from 'react';

import * as ER from 'element-react';

// @ts-ignore
import locale from 'element-react/dist/npm/es5/src/locale';

// @ts-ignore
import en from 'element-react/dist/npm/es5/src/locale/lang/en';
// @ts-ignore
import el from 'element-react/dist/npm/es5/src/locale/lang/el';
// @ts-ignore
import ru from 'element-react/dist/npm/es5/src/locale/lang/ru-RU';
// @ts-ignore
import ua from 'element-react/dist/npm/es5/src/locale/lang/ua';

import * as elements from './libs';

import { useTranslation } from '../../i18n';

export { default as Divider } from './devider';

export const {
  Alert,
  AutoComplete,
  Dropdown,
  Button,
  Form,
  Input,
  Radio,
  Select,
  Slider,
  SelectOption,
  Layout,
  Card,
  Collapse,
  Tag,
  Message,
  Loading,
  Steps,
  Checkbox,
  DatePicker,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} = (elements as any) as {
  Alert: typeof ER.Alert;
  AutoComplete: typeof ER.AutoComplete;
  Dropdown: typeof ER.Dropdown;
  DatePicker: typeof ER.DatePicker;
  Button: typeof ER.Button;
  Form: typeof ER.Form;
  Input: typeof ER.Input;
  Radio: typeof ER.Radio;
  Slider: typeof ER.Slider;
  Select: typeof ER.Select;
  SelectOption: typeof ER.Select.Option;
  Layout: typeof ER.Layout;
  Card: typeof ER.Card;
  Collapse: typeof ER.Collapse;
  Tag: typeof ER.Tag;
  Message: typeof ER.Message;
  Loading: typeof ER.Loading;
  Steps: typeof ER.Steps;
  Checkbox: typeof ER.Checkbox;
};

const mapped = {
  en,
  el,
  ru,
  ua,
};

export const ElementTranslationProvider: React.FC = ({ children }) => {
  const [, { language }] = useTranslation();

  // @ts-ignore
  const lang = mapped[language];

  if (lang) {
    locale.use(lang);
  }

  return <>{children}</>;
};
