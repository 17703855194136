import { Providers } from '../providers';
import { Country } from './index';

const countries: Record<string, Country> = {
  es: {
    code: 'es',
    name: 'common:countries.es',
    flag: '/static/img/flags/es.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  it: {
    code: 'it',
    name: 'common:countries.it',
    flag: '/static/img/flags/it.svg',
    currency: 'EUR',
    provider: Providers.Alfred,
    partnerLogo: '/static/img/partners/alfred-new.svg',
    phoneMask: '3\\99999999999',
  },
  pt: {
    code: 'pt',
    name: 'common:countries.pt',
    flag: '/static/img/flags/pt.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  fr: {
    code: 'fr',
    name: 'common:countries.fr',
    flag: '/static/img/flags/fr.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  gb: {
    code: 'gb',
    name: 'common:countries.gb',
    flag: '/static/img/flags/gb.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  ru: {
    code: 'ru',
    name: 'common:countries.ru',
    flag: '/static/img/flags/ru.svg',
    phoneMask: '79999999999',
    currency: 'EUR',
    provider: Providers.RusPost,
    partnerLogo: '/static/img/partners/ruspost.svg',
  },
  by: {
    code: 'by',
    name: 'common:countries.by',
    flag: '/static/img/flags/by.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  kz: {
    code: 'kz',
    name: 'common:countries.kz',
    flag: '/static/img/flags/kz.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  uz: {
    code: 'uz',
    name: 'common:countries.uz',
    flag: '/static/img/flags/uz.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  md: {
    code: 'md',
    name: 'common:countries.md',
    flag: '/static/img/flags/md.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  lv: {
    code: 'lv',
    name: 'common:countries.lv',
    flag: '/static/img/flags/lv.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  lt: {
    code: 'lt',
    name: 'common:countries.lt',
    flag: '/static/img/flags/lt.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  ee: {
    code: 'ee',
    name: 'common:countries.ee',
    flag: '/static/img/flags/ee.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  us: {
    code: 'us',
    name: 'common:countries.us',
    flag: '/static/img/flags/us.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  ca: {
    code: 'ca',
    name: 'common:countries.ca',
    flag: '/static/img/flags/ca.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  au: {
    code: 'au',
    name: 'common:countries.au',
    flag: '/static/img/flags/au.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  jp: {
    code: 'jp',
    name: 'common:countries.jp',
    flag: '/static/img/flags/jp.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  cy: {
    code: 'cy',
    name: 'common:countries.cy',
    flag: '/static/img/flags/cy.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  ge: {
    code: 'ge',
    name: 'common:countries.ge',
    flag: '/static/img/flags/ge.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  ro: {
    code: 'ro',
    name: 'common:countries.ro',
    flag: '/static/img/flags/ro.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  bg: {
    code: 'bg',
    name: 'common:countries.bg',
    flag: '/static/img/flags/bg.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  cz: {
    code: 'cz',
    name: 'common:countries.cz',
    flag: '/static/img/flags/cz.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  hu: {
    code: 'hu',
    name: 'common:countries.hu',
    flag: '/static/img/flags/hu.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },

  // working
  el: {
    code: 'el',
    name: 'common:countries.el',
    flag: '/static/img/flags/el.svg',
    currency: 'EUR',
    provider: Providers.None,
    partnerLogo: '',
  },
  gr: {
    code: 'gr',
    name: 'common:countries.gr',
    flag: '/static/img/flags/el.svg',
    currency: 'EUR',
    provider: Providers.SF,
    partnerLogo: '/static/img/partners/sf.svg',
    phoneMask: '309999999999',
    postCodeMask: '999 99',
  },
  pl: {
    code: 'pl',
    name: 'common:countries.pl',
    flag: '/static/img/flags/pl.svg',
    currency: 'PLN',
    provider: Providers.InPost,
    partnerLogo: '/static/img/partners/inpost.svg',
    phoneMask: '48999999999',
    postCodeMask: '99-999',
  },
  uk: {
    code: 'uk',
    name: 'common:countries.uk',
    flag: '/static/img/flags/en.svg',
    currency: 'GBP',
    // provider: Providers.Hermes,
    provider: Providers.InPostGB,
    partnerLogo: '/static/img/partners/inpost.svg',
    // partnerLogo: '/static/img/partners/hermes.svg',
    phoneMask: '449999999999',
  },
  de: {
    code: 'de',
    name: 'common:countries.de',
    flag: '/static/img/flags/de.svg',
    currency: 'EUR',
    provider: Providers.GLS,
    partnerLogo: '/static/img/partners/gls.svg',
    phoneMask: '4\\999999999999',
  },
  ua: {
    code: 'ua',
    name: 'common:countries.ua',
    flag: '/static/img/flags/ua.svg',
    currency: 'UAH',
    provider: Providers.UKRPost,
    partnerLogo: '/static/img/partners/up.png',
    phoneMask: '380999999999',
    postCodeMask: '99999',
  },
};

// aliases
countries.greece = countries.el;
countries.ita = countries.it;

export { countries };
