import { Providers } from './providers';

import { Country, getProviderCountry } from './countries';

const providerSlugMap = {
  [Providers.InPost]: 'poland-inpost',
  [Providers.Hermes]: 'england-hermes',
  [Providers.InPostGB]: 'england-inpost',
  [Providers.GLS]: 'germany-gls',
  [Providers.SF]: 'greece-smartpost',
  [Providers.Alfred]: 'italy-alfred',
};

const slugProviderMap = Object.entries(providerSlugMap).reduce((acc, [provider, path]) => {
  acc[path] = provider as Providers;

  return acc;
}, {} as Record<string, Providers>);

type ProviderKey = keyof typeof providerSlugMap | string;

// @ts-ignore
export const getProviderSlug = (provider: ProviderKey): string => `/${providerSlugMap[provider] || ''}`;
export const toProvider = (dispatchProvider: Providers, inboundProvider: Providers, bookService?: string): string => {
  let url = `/rates${getProviderSlug(dispatchProvider)}?to=${inboundProvider}`;

  if (bookService) {
    url += `&via=${bookService}`;
  }

  return url;
};

export const getCountryBySlug = (slug: string): Country | undefined => {
  const provider = slugProviderMap[slug];

  return getProviderCountry(provider as Providers);
};
