import React from 'react';
import classNames from 'classnames';

interface Props {
  type?: 'horizontal' | 'vertical';
}

const Divider: React.FC<Props> = ({ type }) => (
  <div
    className={classNames('el-divider', {
      'el-divider--horizontal': type === 'horizontal',
      'el-divider--vertical': type === 'vertical',
    })}
  />
);

export default Divider;
