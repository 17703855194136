import { Providers } from '../providers';
import { CURRENCY } from '../../helpers/currency';

import { countries } from './list';

type MaskType = string | string[];

export interface Country {
  name: string;
  code: string;
  flag: string;
  currency: CURRENCY;
  provider: Providers;
  partnerLogo: string;
  phoneMask?: MaskType;
  fixedPhoneMask?: MaskType;
  postCodeMask?: MaskType;
}

export { countries };

export const dispatchProviders: Providers[] = [
  Providers.InPost,
  Providers.InPostGB,
  Providers.GLS,
  Providers.SF,
  Providers.Alfred,
];

export const getCountry = (code: string): Country => countries[code.toLowerCase()];
export const getCountryFlag = (code: string): string => (getCountry(code.toLowerCase()) || ({} as Country)).flag;
export const getProviderCountry = (provider: Providers): Country | undefined =>
  Object.values(countries).find((v) => v.provider === provider);
