import dynamic from 'next/dynamic';

import Dropdown from 'element-react/dist/npm/es5/src/dropdown';
import Button from 'element-react/dist/npm/es5/src/button';
import Form from 'element-react/dist/npm/es5/src/form';
import Input from 'element-react/dist/npm/es5/src/input';
import Layout from 'element-react/dist/npm/es5/src/layout';
import Card from 'element-react/dist/npm/es5/src/card';
import Collapse from 'element-react/dist/npm/es5/src/collapse';
import Tag from 'element-react/dist/npm/es5/src/tag';
import Message from 'element-react/dist/npm/es5/src/message';
import Steps from 'element-react/dist/npm/es5/src/steps';
import Radio from 'element-react/dist/npm/es5/src/radio';
import Checkbox from 'element-react/dist/npm/es5/src/checkbox';
import Alert from 'element-react/dist/npm/es5/src/alert';

import SliderComponent from './slider';

export const Slider = SliderComponent;

export const Loading = dynamic(() => import('element-react/dist/npm/es5/src/loading'), {
  ssr: false,
});

export const DatePicker = dynamic(
  () =>
    import('element-react/dist/npm/es5/src/date-picker').then(bundle => {
      return bundle.DatePicker;
    }),
  {
    ssr: false,
  }
);

export const Select = dynamic(() => import('element-react/dist/npm/es5/src/select'), {
  ssr: false,
});

export const SelectOption = dynamic(() => import('element-react/dist/npm/es5/src/select/Option'), {
  ssr: false,
});

export const AutoComplete = dynamic(() => import('element-react/dist/npm/es5/src/auto-complete'), {
  ssr: false,
});

export { Dropdown, Button, Radio, Form, Input, Layout, Card, Collapse, Tag, Message, Steps, Checkbox, Alert };
